import { useNavigate } from '@modern-js/runtime/router';
import { useEffect } from 'react';

// https://modernjs.dev/guides/basic-features/routes.html#%E9%80%9A%E9%85%8D%E8%B7%AF%E7%94%B1
// 没有匹配的路由会渲染这个组件
function PageRedirect() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/writing', { replace: true });
  }, []);

  return null;
}

export default PageRedirect;
